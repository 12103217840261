import { Meta, Title } from "@solidjs/meta";
import { useNavigate, useParams, useSearchParams } from "@solidjs/router";
import { createSignal, Match, onMount, Switch } from "solid-js";
import { pluto_reveal_meta_image } from "~/assets/assets";
import ClientOnlyComponent, {
  ClientComponent,
} from "~/client_only_components/client_component";
import PlutoRevealLanding from "~/components/gifting/pluto-receiver/pluto-receiver-landing/pluto_reveal_landing";
import {
  getPlutoRevealLandingRouteData,
  PlutoRevealLandingRouteData,
} from "~/components/gifting/pluto-receiver/pluto-receiver-landing/pluto_reveal_route_data";
import { createJob } from "~/utils/job";
import attachPopStateListener from "~/utils/popstate_listener";
import { ThreeDotLoader } from "~/widgets/button";

export default function PlutoRevealLandingRoute() {
  const params = useParams();
  const giftingKey = params.giftingKey as string;
  const [errorMessage, setErrorMessage] = createSignal<string | undefined>();

  const [queryParams, setQueryParams] = useSearchParams();

  const isPreview = queryParams.isPreview === "true";
  const amount = Number(queryParams.amount ?? "5000");

  const getGiftBoxDataJob = createJob<PlutoRevealLandingRouteData>({
    initialJob: async () => {
      return await getPlutoRevealLandingRouteData({
        giftingKey: giftingKey,
        isPreview: isPreview,
      });
    },
    errorCallback: (error) => {
      setErrorMessage(error.message);
    },
    successCallback: async (response) => {
      setErrorMessage(undefined);
    },
  });

  onMount(() => {
    attachPopStateListener();
    getGiftBoxDataJob.run();
  });

  const navigate = useNavigate();

  return (
    <>
      <ClientOnlyComponent component={ClientComponent.SnackbarHost} />
      <ClientOnlyComponent component={ClientComponent.ModalHost} />
      <MetaTags />
      <script src="https://unpkg.com/@lottiefiles/lottie-player@0.3.0/dist/lottie-player.js"></script>
      <Switch>
        <Match
          when={
            getGiftBoxDataJob.jobState() == "success" &&
            getGiftBoxDataJob.jobResult() != null
          }
        >
          <PlutoRevealLanding
            giftBoxMetadata={getGiftBoxDataJob.jobResult()!.giftBoxMetadata}
            giftingKey={giftingKey}
            isRtu={getGiftBoxDataJob.jobResult()!.isRtu}
            sessionId={getGiftBoxDataJob.jobResult()!.sessionId}
            isPreview={isPreview}
            navigateToDetailsRoute={() => {
              let link = `/pluto/reveal/${giftingKey}/gift-details`;
              if (isPreview) {
                let amt =
                  getGiftBoxDataJob.jobResult()!.giftBoxMetadata
                    .plutoGiftDetails?.amount ?? amount;
                link = `${link}?isPreview=true&amount=${amt}`;
              }
              navigate(link);
            }}
          />
        </Match>
        <Match when={getGiftBoxDataJob.jobState() == "error"}>
          <div class="text-center">{errorMessage()}</div>
        </Match>
        <Match
          when={
            getGiftBoxDataJob.jobState() == "running" ||
            getGiftBoxDataJob.jobState() == "idle"
          }
        >
          <div class=" flex h-full w-full flex-col items-center justify-center bg-baseDark">
            <ThreeDotLoader color="#fff" />
          </div>
        </Match>
      </Switch>
    </>
  );

  function MetaTags() {
    return (
      <>
        <Title>{`A gift for you`}</Title>
        <Meta name="title" content={`A gift for you`} />
        <Meta name="image" content={pluto_reveal_meta_image} />
      </>
    );
  }
}
