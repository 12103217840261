import { createEffect, createSignal, onMount, Show } from "solid-js";
import {
  createdUsingPlutoBranding,
  gift_cover_v2,
  glitter_lottie,
  plutoReceiverBg,
} from "~/assets/assets";
import { Spacer } from "~/widgets/spacer";
import { buildUpArrowIndicator } from "./arrows";
import { isMobile } from "~/utils/platform";
import { GiftCardDetails, PaymentDetails } from "~/server/types/pluto";
import { GetGiftBoxV3Response } from "~/server/types/gift";
import { GiftCardComponent } from "./gift_card_component";
import PlutoBranding from "../pluto_branding";

export default function PlutoRevealLanding(props: {
  giftBoxMetadata: GetGiftBoxV3Response;
  giftingKey: string;
  isRtu: boolean;
  sessionId: string | null;
  isPreview: boolean;
  navigateToDetailsRoute: () => void;
}) {
  let threshold = 200; // adjust this value to change the threshold

  const [isSwipeComplete, setSwipeComplete] = createSignal(false);
  const [isSwipeAnimationComplete, setSwipeAnimationComplete] =
    createSignal(false);

  const [swipeAnimationDuration, setSwipeAnimationDuration] = createSignal(500);
  const [transitionCard, setTransitionCard] = createSignal(true);

  let contentHeight: number | undefined;

  function calculateHeights() {
    contentHeight =
      document.getElementById("pluto-reveal-landing-content")?.offsetHeight ??
      400;
  }

  onMount(async () => {
    await import("jquery")
      .then((module) => {
        return ((window as any).$ = module.default);
      })
      .catch((err) => {
        alert(err);
      });
    calculateHeights();
    await runAnimations();

    if (isMobile()) {
      listenToMouseEvents();
    }
  });

  createEffect(async () => {
    if (isSwipeComplete()) {
      document.body.style.overflow = "auto";
      setAnimateInitialOpacity(false);
      await new Promise((resolve) =>
        setTimeout(resolve, swipeAnimationDuration())
      );
      setSwipeAnimationComplete(true);
    } else {
      document.body.style.overflow = "hidden";
    }
  });

  function animateToInitialState() {
    // card
    getGiftCardHTMLElement().css({
      transform: "translateY(20px)",
    });

    //cover
    getGiftCardCoverHTMLElement().animate(
      {
        top: 0,
      },
      swipeAnimationDuration()
    );
  }

  function animateToFinalState() {
    // card
    setTransitionCard(true);
    setSwipeAnimationDuration(500);
    playLottie();

    getGiftCardHTMLElement().css({
      transform: `translateY(${-(contentHeight ?? 0) + 24}px)`,
    });
    //cover
    getGiftCardCoverHTMLElement().animate(
      {
        bottom: "-200vh",
      },
      swipeAnimationDuration()
    );
  }

  function getGiftCardHTMLElement() {
    return $("#pluto-reveal-gift-card");
  }

  function getGiftCardCoverHTMLElement() {
    return $("#pluto-reveal-gift-card-cover");
  }

  function playLottie() {
    try {
      const element = document.getElementById("stars-lottie");
      // @ts-ignore
      element?.seek(0);
      // @ts-ignore
      element?.play();
    } catch (e) {
      console.error(e);
    }
  }

  function StarsLottie() {
    return (
      <lottie-player
        id="stars-lottie"
        src={glitter_lottie}
        autoplay={false}
        loop={false}
        class="scale-[150%]"
      ></lottie-player>
    );
  }

  function listenToMouseEvents() {
    let giftCard = getGiftCardHTMLElement();
    let giftCardCover = getGiftCardCoverHTMLElement();

    let initialMouse = 0;

    $(document).on("mousedown touchstart", function (e: any) {
      if (isSwipeComplete() || !e.originalEvent) {
        return;
      }
      initialMouse = e.clientY || e.originalEvent.touches[0].pageY;
    });

    $(document).on("mouseup touchend", function (e: any) {
      if (isSwipeComplete() || !e.originalEvent) {
        return;
      }
      const currentMouse = e.clientY || e.changedTouches[0].pageY;
      const relativeMouse = currentMouse - initialMouse;
      if (relativeMouse < threshold) {
        setTransitionCard(true);
        animateToInitialState();
        return;
      }
    });

    $(document).on("mousemove touchmove", function (e: any) {
      if (isSwipeComplete() || !e.originalEvent) {
        return;
      }

      const currentMouse = e.clientY || e.originalEvent.touches[0].pageY;
      let swipeDistance = initialMouse - currentMouse;

      if (swipeDistance < 0) {
        return;
      }

      if (transitionCard()) {
        setTransitionCard(false);
      }

      giftCard.css({
        transform: `translateY(${-swipeDistance}px)`,
      });
      giftCardCover.css({
        // bottom: -swipeDistance + "px",
        top: swipeDistance + "px",
      });

      if (swipeDistance > threshold) {
        setTransitionCard(true);
        setSwipeComplete(true);
        animateToFinalState();
      }
    });
  }

  // animation controlling hooks
  const [animateInitialOpacity, setAnimateInitialOpacity] = createSignal(false);
  const [slideNameAndCard, setSlideNameAndCard] = createSignal(false);
  const [animateCardInsideHolder, setAnimateCardInsideHolder] =
    createSignal(false);
  const [animateSlideUpIcons, setAnimateSlideUpIcons] = createSignal(false);
  const [animateDragUpText, setAnimateDragUpText] = createSignal(false);

  async function runAnimations() {
    await new Promise((resolve) => setTimeout(resolve, 300));
    setAnimateInitialOpacity(true);
    await new Promise((resolve) => setTimeout(resolve, 800));
    setSlideNameAndCard(true);
    await new Promise((resolve) => setTimeout(resolve, 500));
    setAnimateCardInsideHolder(true);
    await new Promise((resolve) => setTimeout(resolve, 300));
    setAnimateSlideUpIcons(true);
    await new Promise((resolve) => setTimeout(resolve, 700));
    setAnimateDragUpText(true);
    setTransitionCard(false);
  }

  return (
    <>
      {buildBackground()}
      <div
        onClick={() => {
          if (!isMobile()) {
            setSwipeAnimationDuration(1200);
            if (!isSwipeComplete()) {
              setSwipeComplete(true);
              animateToFinalState();
            }
          }
        }}
        class={`relative flex h-full w-full flex-col items-center justify-start   bg-black px-4 text-center  text-white   `}
        classList={{
          "overflow-y-hidden": !isSwipeComplete(),
        }}
      >
        <div class="pointer-events-none absolute left-[50%] top-[24px] z-40 flex  w-full max-w-[400px] -translate-x-[50%] overflow-hidden  ">
          <StarsLottie />
        </div>
        <div
          id="pluto-reveal-landing-content"
          class="flex flex-1  flex-col items-center justify-start   "
          classList={{
            "opacity-0": isSwipeComplete(),
          }}
        >
          <div class="mt-[10vh]"></div>
          {buildReceiverName()}
          <Spacer height={12} />
          {buildSenderName()}
          <Spacer height={40} />
          {buildDragUpText()}
          <div class="mt-[12px]"></div>
          {buildUpArrowIndicator({
            startAnimation: animateSlideUpIcons,
          })}
          <div class="mt-[44px]"></div>
        </div>
        <div class="relative h-full max-h-[400px] w-full sm:max-w-[363px]">
          {buildGiftCard()}
          <Show when={!isSwipeAnimationComplete()}>
            {buildGiftCardCover()}
            <div
              class="absolute
             bottom-0 left-0 right-0 z-30 transition-opacity duration-300"
              classList={{
                "opacity-0": !animateCardInsideHolder() || isSwipeComplete(),
              }}
            >
              <img src={createdUsingPlutoBranding} alt="" />
            </div>
          </Show>
        </div>
      </div>
    </>
  );

  function buildReceiverName() {
    return (
      <span
        class="text-center text-normal font-light text-[#D9D9D9] duration-[1000ms] ease-[cubic-bezier(.13,1.12,.4,.96)]"
        classList={{
          "opacity-0": !animateInitialOpacity(),
          "opacity-100": animateInitialOpacity(),
          "translate-y-[200px]": !slideNameAndCard(),
          "translate-y-0": slideNameAndCard(),
        }}
      >
        {`${props.giftBoxMetadata.plutoGiftDetails?.occasion.recipientName} 👋`}
      </span>
    );
  }

  function buildSenderName() {
    return (
      <span
        classList={{
          "opacity-0": !animateInitialOpacity(),
          "opacity-100": animateInitialOpacity(),
          "translate-y-[200px]": !slideNameAndCard(),
          "translate-y-0": slideNameAndCard(),
        }}
        class="px-4 text-center font-jakartaSans text-[40px] font-semibold leading-[120%] tracking-[0.4px] duration-[1000ms] ease-[cubic-bezier(.13,1.12,.4,.96)]"
      >
        {`${props.giftBoxMetadata.plutoGiftDetails?.senderName}  sent you a gift`}
      </span>
    );
  }

  function buildDragUpText() {
    return (
      <span
        classList={{
          "opacity-0": !animateDragUpText(),
          "opacity-100": animateDragUpText(),
        }}
        class="text-smallMedium uppercase text-textNormal transition-opacity duration-500"
      >
        <span class="flex sm:hidden">Drag up to reveal</span>
        <span class="hidden sm:flex">Tap to reveal</span>
      </span>
    );
  }

  function buildGiftCard() {
    let giftDetails = getGiftCardDetails();
    return (
      <div
        id="pluto-reveal-gift-card"
        class={`absolute top-[0px] z-10 flex  w-full flex-row  items-start justify-center `}
        classList={{
          "translate-y-[70px] opacity-0": !animateCardInsideHolder(),
          "translate-y-[20px] opacity-100": animateCardInsideHolder(),
          "transition-transform ": transitionCard(),
          "ease-[cubic-bezier(.06,1.25,.37,1.11)]": isSwipeComplete(),
        }}
        style={
          transitionCard()
            ? {
                "transition-duration": `${swipeAnimationDuration()}ms`,
              }
            : {}
        }
      >
        <Show when={giftDetails}>
          <GiftCardComponent
            giftDetails={() => giftDetails!}
            giftingKey={props.giftingKey}
            isRtu={props.isRtu}
            sessionId={props.sessionId}
            giftBoxMetadata={props.giftBoxMetadata}
            isPreview={props.isPreview}
            navigateToDetailsRoute={props.navigateToDetailsRoute}
            playLottie={playLottie}
          />
        </Show>
      </div>
    );
  }

  function buildGiftCardCover() {
    return (
      <div
        id="pluto-reveal-gift-card-cover"
        classList={{
          "translate-y-[450px]": !slideNameAndCard(),
          "translate-y-[1px]": slideNameAndCard(),
        }}
        class={`absolute z-[20] flex w-full flex-row items-center justify-center  transition-transform duration-[1000ms] ease-[cubic-bezier(.13,1.12,.4,.96)]`} //cubic-bezier(.13,1.12,.4,.96)
      >
        <div class="relative w-full  ">
          <img src={gift_cover_v2}></img>
        </div>
      </div>
    );
  }

  function buildBackground() {
    return (
      <div
        class="absolute top-0 z-10 flex aspect-[355/228] w-full bg-no-repeat   transition-opacity duration-500 sm:hidden sm:max-w-[798px]"
        classList={{
          "opacity-0": !animateInitialOpacity(),
          "opacity-100": animateInitialOpacity(),
        }}
        style={{
          "background-image": `url('${plutoReceiverBg}')`,
          "background-size": "100% 100%",
        }}
      ></div>
    );
  }

  function getactivitySlots() {
    let activities = props.giftBoxMetadata.plutoGiftDetails?.activities ?? {};

    return Object.values(activities).map((activity) => {
      return {
        id: activity.id,
        activities: [activity],
      };
    });
  }

  function getGiftCardDetails(): GiftCardDetails | null {
    let giftCardDetails = props.giftBoxMetadata.plutoGiftDetails;
    if (!giftCardDetails) {
      return null;
    }
    return {
      id: "1",
      paymentDetails: {} as PaymentDetails,
      conversationId: "1",
      receiversPreviewUrl: "",
      senderName: giftCardDetails.senderName,
      generatedOn: "2023-06-01",
      paymentStatus: "PAID",
      title: giftCardDetails.title.title,
      brandDetails: giftCardDetails.brandDetails,
      status: "COMPLETED",
      budget: props.giftBoxMetadata.amount,
      content: {
        text: {
          occasion: giftCardDetails.occasion,
          titles: [giftCardDetails.title],
          activitySlots: getactivitySlots(),
        },
        images: {
          occasionImages: [giftCardDetails.occasionImage],
          activityImages: [giftCardDetails.activityImage],
        },
        selection: {
          titleId: giftCardDetails.title.id,
          occasionImageId: giftCardDetails.occasionImage.id,
          activities: {},
          activityImageId: giftCardDetails.activityImage.id,
        },
      },
    } as GiftCardDetails;
  }
}
