import { Accessor, createEffect, createSignal } from "solid-js";

export function buildUpArrowIndicator(props: {
  startAnimation: Accessor<boolean>;
}) {
  const [animateArrow1, setAnimateArrow1] = createSignal(false);
  const [animateArrow2, setAnimateArrow2] = createSignal(false);
  const [animateArrow3, setAnimateArrow3] = createSignal(false);

  createEffect(() => {
    if (props.startAnimation()) {
      setTimeout(() => {
        setAnimateArrow3(true);
      }, 100);
      setTimeout(() => {
        setAnimateArrow2(true);
      }, 200);
      setTimeout(() => {
        setAnimateArrow1(true);
      }, 300);
    }
  });

  return (
    <div class="flex flex-col transition-opacity duration-500">
      <div
        class="transition-all duration-500"
        classList={{
          "translate-y-[30px]": !animateArrow1(),
          "translate-y-[8px]": animateArrow1(),
          "opacity-0": !animateArrow1(),
          "opacity-100": animateArrow1(),
        }}
      >
        {arrowSvg()}
      </div>
      <div
        classList={{
          "translate-y-[15px]": !animateArrow2(),
          "translate-y-[4px]": animateArrow2(),
          "opacity-0": !animateArrow2(),
          "opacity-60": animateArrow2(),
        }}
        class="transition-all duration-500"
      >
        {arrowSvg()}
      </div>
      <div
        class="transition-all duration-500"
        classList={{
          "translate-y-[12px]": !animateArrow3(),
          "translate-y-[0px]": animateArrow3(),
          "opacity-0": !animateArrow3(),
          "opacity-30": animateArrow3(),
        }}
      >
        {arrowSvg()}
      </div>
    </div>
  );
}

function arrowSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      viewBox="0 0 38 20"
      fill="none"
    >
      <path d="M1.5 19L19 1.5L36.5 19" stroke="#E2E2E2" stroke-width="2" />
    </svg>
  );
}
