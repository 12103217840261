export default function GiftCardWhiteIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <rect
        x="2"
        y="4"
        width="21"
        height="15"
        rx="3"
        stroke="#ABABAB"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.7513 11.258C14.7513 8.906 16.3843 7 18.3983 7C19.2653 7 19.9683 7.703 19.9683 8.57C19.9683 10.464 17.6313 12 14.7493 12C11.8673 12 9.53027 10.465 9.53027 8.57C9.53027 7.703 10.2333 7 11.1013 7C13.1153 7 14.7483 8.906 14.7483 11.258"
        stroke="#ABABAB"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.75 15.8535C12.958 15.8535 14.748 13.7635 14.748 11.1855L14.752 11.1995C14.752 13.7775 16.542 15.8675 18.75 15.8675"
        stroke="#ABABAB"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.75 4V19"
        stroke="#ABABAB"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.75 12H2.75"
        stroke="#ABABAB"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
