export default function FlipIconDark() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M9.89087 6.81816C10.5715 6.75171 11.2767 6.7168 11.9992 6.7168C17.7064 6.7168 22.333 8.89545 22.333 11.583C22.333 12.8915 21.2361 14.0795 19.4516 14.954"
        stroke="#313538"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.0912 14.0518L15.2776 16.2382L13.0912 18.4246"
        stroke="#313538"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.9779 4.61719L9.7915 6.80359L11.9779 8.98999"
        stroke="#313538"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.1086 16.2092C14.428 16.2756 13.7228 16.3105 13.0003 16.3105C7.2931 16.3105 2.66652 14.1319 2.66652 11.4444C2.66652 9.98708 4.02692 8.67939 6.18234 7.78756"
        stroke="#313538"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
